import React from 'react'
import { ContactForm } from '../components/contact/ContactForm'

export const LANGUAGES: AppLocale[] = ['de', 'en', 'es', 'nl', 'fr', 'it']
export const CURRENCIES: AppCurrency[] = ['EUR', 'USD', 'GBP']

export const SEARCH_RESULTS_TYPE_ORDER = [
  'city',
  'category',
  'product',
  'region',
  'state',
  'country',
]

export const MAP_TYPES_TO_TERMS = {
  country: 'Land',
  city: 'Stadt',
  categories: 'Kategorie',
  product: 'Aktivität',
}

export const LOCAL_SLUGS = [
  {
    id: 'contact',
    de: 'kontakt',
    en: 'contact',
    es: 'contacto',
    nl: 'contact',
    fr: 'contact',
    it: 'contatto',
    component: () => <ContactForm />,
  },
  {
    id: 'faq',
    de: 'faq',
    en: 'faq',
    es: 'faq',
    nl: 'faq',
    fr: 'faq',
    it: 'faq',
    component: () => <div>faq</div>,
  },
  {
    id: 'sitemap',
    de: 'Sitemap.xml',
    en: 'Sitemap.xml',
    es: 'Sitemap.xml',
    nl: 'Sitemap.xml',
    fr: 'Sitemap.xml',
    it: 'Sitemap.xml',
  },
]

export const SIGHT2SIGHT_FALLBACK_ALT =
  'Sight2Sight Image - Entdecke die Welt mit SIGHT2SIGHT'
export const SIGHT2SIGHT_FALLBACK_TITLE =
  'Sight2Sight Image - Entdecke die Welt mit SIGHT2SIGHT'

export const GLOBAL_FALLBACK_IMAGE: Image = {
  image: {
    asset: {
      _ref: 'image-ab74a41115104e89d32f3dc3caf6345063c21d0e-2000x1332-jpg',
      _type: 'reference',
    },
    crop: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    hotspot: {
      x: 0.5,
      y: 0.5,
      height: 1,
      width: 1,
    },
    _type: 'image',
  },
  _type: 'custom_image',
}

export const AFFILIATE_PARTNERS = [
  {
    name: 'Tiqets',
    logo: '/assets/affiliate_partners/tiqets.png',
  },
  {
    name: 'Civitatis',
    logo: '/assets/affiliate_partners/civitatis.png',
  },
  {
    name: 'Get Your Guide',
    logo: '/assets/affiliate_partners/gyg.png',
    height: 65,
    width: 65,
  },
  {
    name: 'Musement',
    logo: '/assets/affiliate_partners/musement.png',
  },
  {
    name: 'Viator',
    logo: '/assets/affiliate_partners/viator.png',
  },
]

export const PAYMENT_PARTNERS = [
  {
    name: 'Apple',
    logo: '/assets/payment_partners/apple.png',
  },
  {
    name: 'Klarna',
    logo: '/assets/payment_partners/klarna.png',
  },
  {
    name: 'Mastercard',
    logo: '/assets/payment_partners/mastercard.png',
  },
  {
    name: 'PayPal',
    logo: '/assets/payment_partners/paypal.png',
  },
  {
    name: 'Visa',
    logo: '/assets/payment_partners/visa.png',
  },
  {
    name: 'Sepa',
    logo: '/assets/payment_partners/sepa.png',
  },
]

export const LEGAL = [
  {
    title: 'legal',
    content: [{ id: 'imprint' }, { id: 'terms' }, { id: 'privacy' }],
  },
  {
    title: 'service',
    content: [{ id: 'cookie-settings' }, { id: 'contact' }, { id: 'sitemap' }],
  },
  {
    title: 'contact-title',
    content: [
      { id: 'email' },
      { id: 'firma' },
      { id: 'website-url', href: '' },
    ],
  },
]

export const ICONS_FOR_CATEGORIES = {
  'Alle Aktivitäten': 'fa-duotone fa-landmark #004a78',
  'Aktivitäten für Pärchen': 'fa-duotone fa-people-pants #a62182',
  'Ausflüge mit Kindern': 'fa-duotone fa-child-reaching #f777b8',
  'Sightseeing Touren': 'fa-duotone fa-landmark-magnifying-glass #bcae91',
  'Selbstgeführte Touren': 'fa-duotone fa-headphones-simple #fbba00',
  Geheimtipps: 'fa-duotone fa-key #575757',
  Stadtführungen: 'fa-duotone fa-people-group #f79a20',
  'Food Touren': 'fa-duotone fa-utensils #12db81',
  'Kulinarische Stadtführungen': 'fa-duotone fa-bowl-spoon #ffa366',
  'Eintrittskarten & Tickets': 'fa-duotone fa-ticket #ffc106',
  'Fahrrad Touren': 'fa-duotone fa-bicycle #3bb97a',
  'Private Touren': 'fa-duotone fa-user-lock #bcae91',
  Events: 'fa-duotone fa-calendar-star #f27474',
  Transfers: 'fa-duotone fa-van-shuttle #969698',
  Kanalfahrten: 'fa-duotone fa-bridge-water #8564ed',
  Hafenrundfahrten: 'fa-duotone fa-ship #26a5ef',
  Tagesausflüge: 'fa-duotone fa-route #01998a',
  Stadtrundfahrten: 'fa-duotone fa-bus #ff6666',
  Schifffahrten: 'fa-duotone fa-ferry #9ee0ff',
  Bootstouren: 'fa-duotone fa-anchor #0e6e9c',
  'City Cards': 'fa-duotone fa-memo-circle-info #5d3ebc',
  'Kombi-Touren': 'fa-duotone fa-user-plus #fd3a9d',
  Schulklassen: 'fa-duotone fa-user-graduate #b71f36',
  'Free Walking Touren': 'fa-duotone fa-shoe-prints #d6203c',
  Flussfahrten: 'fa-duotone fa-water #2544c2',
  Schnorcheltouren: 'fa-duotone fa-mask-snorkel #0055c4',
  'Hop-On Hop-Off': 'fa-duotone fa-shuffle #ff6666',
}

// Constants for city slugs
const CITY_SLUGS = {
  BREMEN: 'bremen',
  HAMBURG: 'hamburg',
  BERLIN: 'berlin',
}

// Map of city names in different languages
export const STATE_NAMES = {
  [CITY_SLUGS.BREMEN]: {
    de: 'hansestadt-bremen',
    en: 'hanseatic-city-of-bremen',
    es: 'ciudad-hanseática-de-bremen',
    nl: 'hanzestad-bremen',
    fr: 'ville-hanseatique-de-breme',
    it: 'citta-anseatica-di-brema',
  },
  [CITY_SLUGS.HAMBURG]: {
    de: 'hansestadt-hamburg',
    en: 'hanseatic-city-of-hamburg',
    es: 'ciudad-hanseática-de-hamburgo',
    nl: 'hanzestad-hamburg',
    fr: 'ville-hanseatique-de-hambourg',
    it: 'citta-anseatica-di-amburgo',
  },
  [CITY_SLUGS.BERLIN]: {
    de: 'hauptstadt-berlin',
    en: 'capital-berlin',
    es: 'capital-berlín',
    nl: 'hoofdstad-berlijn',
    fr: 'capitale-berlin',
    it: 'capitale-berlino',
  },
}

// Map of city destinations in different languages
export const CITY_DESTINATIONS = {
  [CITY_SLUGS.BREMEN]: {
    de: 'bremen',
    en: 'bremen',
    es: 'bremen',
    nl: 'bremen',
    fr: 'breme',
    it: 'brema',
  },
  [CITY_SLUGS.HAMBURG]: {
    de: 'hamburg',
    en: 'hamburg',
    es: 'hamburgo',
    nl: 'hamburg',
    fr: 'hambourg',
    it: 'amburgo',
  },
  [CITY_SLUGS.BERLIN]: {
    de: 'berlin',
    en: 'berlin',
    es: 'berlin',
    nl: 'berlijn',
    fr: 'berlin',
    it: 'berlino',
  },
}
