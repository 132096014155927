export function getPrice(
  localePriceAgeRange: PriceAgeRange[],
  locale: AppLocale,
  currencyAsISO4217?: boolean,
  getLowestPrice?: boolean,
): { price: string; currency: string } {
  if (!localePriceAgeRange || localePriceAgeRange.length === 0) {
    return { price: 'Price not available', currency: '' }
  }

  // Mapping from currency symbols to ISO 4217 codes
  const currencySymbolToISO = {
    '€': 'EUR', // Euro
    // Add more mappings as necessary
  }

  const relevantPrice = getLowestPrice
    ? localePriceAgeRange.reduce((min, current) =>
        current.price < min.price ? current : min,
      )
    : localePriceAgeRange.reduce((max, current) =>
        current.price > max.price ? current : max,
      )

  // Determine if the original currency is a symbol and map it to ISO 4217 code if necessary
  const originalCurrency = relevantPrice.currency
  const currencyISOCode =
    currencySymbolToISO[originalCurrency as keyof typeof currencySymbolToISO] ||
    originalCurrency
  const isWholeNumber = relevantPrice.price % 1 === 0

  // Format the price according to the locale and whether the price is a whole number
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyISOCode, // Use the ISO code for formatting
    minimumFractionDigits: isWholeNumber ? 0 : 2,
    maximumFractionDigits: 2,
  })

  // Extract the numeric part of the formatted price

  const formattedPrice = formatter.format(relevantPrice.price)
  const numericPrice = formattedPrice.replace(/[^\d.,]/g, '')

  // Decide what to return as the currency: ISO code if currencyAsISO4217 is true, original symbol otherwise
  const currencyForReturn = currencyAsISO4217
    ? currencyISOCode
    : originalCurrency

  return {
    price: numericPrice,
    currency: currencyForReturn,
  }
}

const SYMBOL_TO_CURRENCY = {
  EUR: '€',
  USD: '$',
  GBP: '£',
}
export function formatPrice({
  price,
  currency,
}: {
  price: string
  currency: string
}) {
  const symbolsBeforePrice = ['USD', 'GBP']
  const isSymbolBefore = symbolsBeforePrice.includes(currency)

  // Replace comma with dot for decimal separation
  const numericPrice = price.replace ? price.replace(',', '.') : price

  let formattedPrice
  if (Number(numericPrice) % 1 === 0) {
    // Price is a whole number, no need to format decimal places
    formattedPrice = Number(numericPrice).toString().replace('.', ',')
  } else {
    // Price has decimal places, fix to two places
    formattedPrice = Number(numericPrice).toFixed(2).replace('.', ',')
  }

  // Get currency symbol - handle the case if currency is not in the mapping
  const currencySymbol =
    SYMBOL_TO_CURRENCY[currency as keyof typeof SYMBOL_TO_CURRENCY] || currency

  const priceWithSymbol = isSymbolBefore
    ? `${currencySymbol} ${formattedPrice}`
    : `${formattedPrice} ${currencySymbol}`

  return priceWithSymbol
}
