import React from 'react'

import { useTranslation } from 'next-i18next'
import { IdealImage } from '../shared/IdealImage'
import { useIsMobile } from '../../lib/hooks/useIsMobile'
import { Logo } from '../header/Logo'
import { formatPrice } from '../../lib/helper/price'
import { getHref } from '../../lib/helper/helper'
import Link from '../overwrites/Link'

interface Props {
  results: SearchResult[]
  highlightedIndex: number
  setHighlightedIndex: (index: number) => void
  onResultSelect: () => void
  searchResultsRef: React.RefObject<HTMLDivElement>
  searchFocused: boolean
}

export const SearchResults: React.FC<Props> = ({
  results,
  highlightedIndex,
  setHighlightedIndex,
  onResultSelect,
  searchResultsRef,
  searchFocused,
}) => {
  const isMobile = useIsMobile()
  const { t, i18n } = useTranslation('common')
  const searchResultsHeight =
    window.visualViewport.height - 54 - (isMobile ? 0 : 50)

  const resultDescription = (item: SearchResult) =>
    [
      item.city?.name,
      item?._type ? t(item?._type) : undefined,
      item.price_from
        ? `${t('from')} ${formatPrice({
            price: item.price_from.EUR.toString(),
            currency: 'EUR',
          })}`
        : undefined,
    ]
      .filter(Boolean)
      .join(' • ')

  const noResults = (
    <li>
      <span className="text-md font-medium flex justify-center my-4">
        {t('no-results')}
      </span>
    </li>
  )

  return (
    <div
      ref={searchResultsRef}
      className={`absolute top-full left-0 xs:-left-24 right-0 xs:-right-24 ${searchFocused ? 'mt-1' : 'mt-3'} ${!isMobile || !searchFocused ? 'border-2' : ''} xs:mt-4 max-w-2xl bg-white overflow-y-auto xs:rounded-lg`}
      role="list"
      aria-live="polite"
    >
      <ul
        style={{
          height: isMobile ? `${searchResultsHeight}px` : ``,
          maxHeight: isMobile ? '' : '70vh',
        }}
        className="flex flex-col gap-0.5 py-1"
      >
        {!!results.length
          ? results.map((item, index) => {
              const { has_product_page = false, product_link, _type } = item
              const href =
                _type === 'product'
                  ? !has_product_page
                    ? product_link
                    : getHref(item, i18n.language as AppLocale)
                  : getHref(item, i18n.language as AppLocale)
              return (
                <li key={item._id}>
                  <Link
                    href={href}
                    target={
                      _type === 'product' && !has_product_page
                        ? '_blank'
                        : undefined
                    }
                    onClick={onResultSelect}
                    onMouseEnter={() => setHighlightedIndex(index)}
                    className={`flex justify-between items-center p-1 gap-2 mx-1 rounded-lg cursor-pointer ${index === highlightedIndex ? 'bg-gray-200' : ''}`}
                  >
                    <div className="flex flex-1 min-w-0 items-center gap-2">
                      <div className="w-12 min-w-12 h-8 relative overflow-hidden rounded-sm">
                        <IdealImage
                          image={item.coverImage || item.image}
                          alt={
                            t('search-result-image') +
                            ' ' +
                            (item.name || item.h1)
                          }
                        />
                      </div>
                      <div className="flex flex-col justify-start min-w-0">
                        <span className="whitespace-nowrap text-xs text-black truncate">
                          {resultDescription(item)}
                        </span>
                        <span className="whitespace-nowrap text-sm truncate">
                          {item.name || item.h1}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })
          : noResults}
        {isMobile && (
          <li className="min-h-32 flex items-center justify-center">
            <Logo />
          </li>
        )}
      </ul>
    </div>
  )
}
