import { useTranslation } from 'next-i18next'
import { LEGAL, LOCAL_SLUGS } from '../../lib/constants'
import { useEffect, useState } from 'react'
import { getClient } from '../../lib/sanity.client'
import Link from '../overwrites/Link'

const openCookies = () => {
  ;(window as any).Cookiebot?.show && (window as any).Cookiebot?.show()
}

const Legal = () => {
  const { t, i18n } = useTranslation('common')
  const client = getClient()
  const [legalSlugs, setLegalSlugs] = useState<Record<string, object>>({})

  useEffect(() => {
    const fetchLegalSlugs = async () => {
      try {
        const query = `
          *[_type in ["imprint", "privacy", "terms"]] {
            _id,
            "name": name.${i18n.language},
            "slug": slug.${i18n.language}.current,
          }
        `
        const legalDocuments: { _id: string; name: string; slug: string }[] =
          await client.fetch(query)
        const slugs = legalDocuments.reduce(
          (acc, doc: any) => {
            acc[doc._id as keyof typeof acc] = {
              name: doc.name,
              slug: doc.slug,
            }
            return acc
          },
          {
            faq: {
              name: t('faq'),
              slug: (LOCAL_SLUGS.find((s) => s.id === 'faq') || {})[
                i18n.language as keyof (typeof LOCAL_SLUGS)[0]
              ],
            },
            contact: {
              name: t('contact'),
              slug: (LOCAL_SLUGS.find((s) => s.id === 'contact') || {})[
                i18n.language as keyof (typeof LOCAL_SLUGS)[0]
              ],
            },
            sitemap: {
              name: t('sitemap'),
              slug: (LOCAL_SLUGS.find((s) => s.id === 'sitemap') || {})[
                i18n.language as keyof (typeof LOCAL_SLUGS)[0]
              ],
            },
          },
        )
        setLegalSlugs(slugs)
      } catch (error) {
        console.error('Error fetching legal slugs:', error)
      }
    }

    fetchLegalSlugs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <div className="flex flex-col items-center md:flex-row justify-center gap-x-12 gap-y-6 flex-wrap">
      {LEGAL.map((legal, index) => {
        return (
          <div
            key={legal.title}
            className="flex flex-col w-58 xs:max-w-xs max-w-md w-full border rounded-lg p-3"
          >
            <div
              className="text-xl font-medium mb-4 uppercase"
              style={{ color: '#4d4d4d' }}
            >
              {t(legal.title)}
            </div>
            <ul className="list-disc list-inside space-y-3 xs:space-y-1">
              {legal.content.map((item, i) => {
                const { slug, href, name, id } =
                  legalSlugs[item.id] || (item as any)
                const link =
                  id !== 'cookie-settings'
                    ? '/' + (href || slug || '')
                    : undefined
                const linkText = name || t(id)
                if (!linkText) {
                  return <li className="invisible" key={item.id + i} />
                }
                return (
                  <li key={item.id} className="flex items-center gap-2">
                    <span className="fa-solid fa-arrow-right text-primary" />
                    {link && index !== 2 ? (
                      <Link
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm hover:underline"
                      >
                        {linkText}
                      </Link>
                    ) : id === 'cookie-settings' ? (
                      <div
                        onClick={openCookies}
                        className="text-sm cursor-pointer"
                      >
                        {linkText}
                      </div>
                    ) : (
                      <div className="text-sm">{linkText}</div>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default Legal
