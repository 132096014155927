import { groq } from 'next-sanity'

export const getAllProductsQuery = (
  locale: AppLocale,
  offset?: number,
  packageSize?: number,
) => groq`
  *[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current)))] 
  | order(coalesce(priority, 0) desc, coalesce(reviews.numberOfReviews, 0) desc)
  {
    ${getReducedProductFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + (packageSize || 50)}]`}
`

export const getAllProductsCountQuery = (locale: AppLocale) => groq`
  count(*[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current)))])
`

export const getAllBookmarkedProductsQuery = (
  locale: AppLocale,
  productIds: string[],
) => groq`
  *[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current))) && _id in ["${productIds.join('", "')}"]] {
    ${getReducedProductFields(locale)}
  }
`

export const getAllLastViewedProductsQuery = (
  locale: AppLocale,
  productIds: string[],
  offset?: number,
) => groq`
  *[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current))) && _id in ["${productIds.join('", "')}"]] {
    ${getReducedProductFields(locale)}
  }${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllProductsInLocationQuery = (
  locale: AppLocale,
  locationType: 'location' | 'state' | 'region',
  locationId: string,
  ignoreId?: string,
  offset?: number,
) => {
  let locationQueryPart = ''
  if (locationType === 'state') {
    locationQueryPart = ` && location[0]->state->_id == "${locationId}"`
  } else if (locationType === 'region') {
    locationQueryPart = ` && location[0]->region->_id == "${locationId}"`
  } else {
    locationQueryPart = ` && references("${locationId}")`
  }

  let ignoreIdQueryPart = ''
  if (ignoreId) {
    ignoreIdQueryPart = ` && _id != "${ignoreId}"`
  }

  return groq`
    *[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current)))${ignoreIdQueryPart}${locationQueryPart}]
    | order(coalesce(priority, 0) desc, coalesce(reviews.numberOfReviews, 0) desc)
    {
      ${getReducedProductFields(locale)}
    }
    ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
  `
}

const getReducedProductFields = (locale: AppLocale) => `
  _id,
  _type,
  _updatedAt,
  "h1": h1.${locale},
  slug,
  image,
  product_badge,
  has_product_page,
  reviews,
  priority,
  product_link,
  price_from,
  "location": location[0]->{
    _type,
    "name": name.${locale},
    slug,
  }
`

export const getProductBySlugQuery = (
  locale: AppLocale,
  productSlug: string,
) => groq`
  *[_type == "product" && slug.${locale}.current == "${productSlug}"][0] {
    ${getFullProductFields(locale)}
  }
`

export const getSimilarProductsQuery = (
  locale: AppLocale,
  categoryIds: string[],
  ignoreId?: string,
  offset?: number,
) => {
  const categoryIdsString = `["${categoryIds.join('", "')}"]`
  return groq`
    *[_type == "product" && _id != "${ignoreId}" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current))) && count((category[]->_id)[@ in ${categoryIdsString}]) > 0]
    | order(coalesce(priority, 0) desc, coalesce(reviews.numberOfReviews, 0) desc)
    {
      ${getReducedProductFields(locale)}
    }
    ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
  `
}

export const getAllProductsInCountryQuery = (
  locale: AppLocale,
  countryId: string,
  offset?: number,
) => groq`
  *[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current))) && location[0]->country->_id == "${countryId}"]
  | order(coalesce(priority, 0) desc, coalesce(reviews.numberOfReviews, 0) desc)
  {
    ${getReducedProductFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

export const getAllProductsInCategoryQuery = (
  locale: AppLocale,
  categoryId: string,
  offset?: number,
) => groq`
  *[_type == "product" && (has_product_page == false || (has_product_page == true && defined(slug.${locale}.current))) && "${categoryId}" in category[]->_id]
  | order(coalesce(priority, 0) desc, coalesce(reviews.numberOfReviews, 0) desc)
  {
    ${getReducedProductFields(locale)}
  }
  ${offset === -1 ? '' : `[${offset || 0}...${(offset || 0) + 50}]`}
`

const getFullProductFields = (locale: AppLocale) => `
  _id,
  _type,
  "h1": h1.${locale},
  slug,
  image,
  has_product_page,
  product_badge,
  "product_class": product_class -> {
    "text": text.${locale}
  },
  reviews,
  product_link,
  "booking_engine": booking_engine.${locale},
  "seo": seo.${locale},
  "price_old": coalesce(price_old.${locale}, price_old.de),
  price_from,
  "times": times.${locale},
  "duration": duration.${locale},
  "languages": languages[]-> {
    _id,
    "text": text.${locale},
    image
  },
  "meeting_point": meeting_point.${locale},
  "cancellation_rebook": cancellation_rebook.${locale},
  "further_information": further_information[].${locale},
  "inclusions": inclusions[].${locale},
  "description_1": description_1.${locale},
  "description_2": description_2.${locale},
  "description_3": description_3.${locale},
  "faq": faq.${locale},
  "highlights": highlights[] {
    _id,
    fa_icon,
    "title": title.${locale},
    "subtitle": subtitle.${locale},
  },
  "route": route.${locale},
  "location": location[0]->{
    _id,
    _type,
    "name": name.${locale},
    "name_description": name_description.${locale},
    product_link,
    "cover_image": coverImage,
    slug,
    "country": country-> {
      _id,
      "name": name.${locale},
      "name_description": name_description.${locale},
      "slug": slug.${locale}.current
    },
  },
  "category": category[]->{
    _id,
    "name": name.${locale},
    "slug": slug.${locale}.current
  },
  "meta_title": meta_title.${locale},
  "meta_description": meta_description.${locale},
  partner_product_id
`
